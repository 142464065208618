@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundatinon-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

// @include font-awesome

@import 'font-awesome';

// @include custom font

@import url('https://fonts.googleapis.com/css?family=Lato');
@import 'components/building-blocks/breadcrumb-counter-nav';

@import url("https://use.typekit.net/wdn2hpl.css");

// 1. Main
// --------

/*body {overflow: hidden;}*/

.logo {
  margin: .5em 0 1.5em;
  padding: 1em 1em .8em;
  text-align: center;
  height: 100px;
  width: 100%;

  & img {
    max-height: 100%;
  }

}

.title_wrapper {
  background-color: lightgrey;
  padding-top: 1.25em;
  padding-bottom: .625em;

  & h1 {
    font-size: 1.5rem;
    color: #272727;
    font-weight: 300;
    letter-spacing: 0.016em;
    padding-top: 0.3rem;
  }
}

.headline {

  & h2 {
    font-size: 2rem;
    color: #272727;
    font-weight: 300;
    letter-spacing: 0.016em;
    margin: 3.5rem 0 0 0;
    text-align: center !important;
  }

}

.dvt_caption {
  text-align: center !important;
  padding: 0.8rem 0;
  & i {
    font-size: 0.8rem
  }
}

.halfwidth {
  max-width: 50rem;
}

.link_line {
  color: #272727;
  font-weight: 300;
  letter-spacing: 0.016em;
  margin: 1rem 0;
  text-align: center !important;

  & .link_line_list {
    display: inline-block;
  }
  & li {
    float: left;
    border-right: 1px solid #272727;

  }

  & li:last-child {

    border: none;

  }
}

.fa-stack:focus {
  outline: none;
}

textarea {
  height: 10.8rem !important;
  width: 100% !important;
}

.teaser_wrapper {
  margin-top: 3rem;

  & img {
    max-height: 333px;
  }
}

.sub_caption, .head_caption {
  padding: 0 5rem;
}

.btn-line, .btn-line-reveal {

  margin: 2rem 0 -1rem 0;
  & span {
    padding-left: 0.5rem;
    font-weight: bold;
  }
  & i {

    font-size: 0.8rem;
  }

  & .button {
    padding: 1rem
  }
}

.btn-line-reveal {
  margin: 2rem 3rem 0 3rem;
}

#dvt_info {
  list-style: none;
  margin-left: -0.9rem;
  margin-bottom: 3rem;

  & i {

    font-size: 0.8rem;
    padding-right: 0.5rem;
  }

  & li {
    float: left;
    border-right: 1px solid #272727;
    color: #272727;
    font-weight: 300;
    letter-spacing: 0.016em;
    padding: 0 1rem;
    text-align: left;

  }

  & li:last-child {

    border: none;

  }
}

#headline {

  padding-top: 2.25em;
  padding-bottom: .625em;

  & h1 {
    font-size: 1.8rem;
    color: #272727;
    font-weight: bold;
    letter-spacing: 0.016em;
  }
}

#menubtn {
  text-align: left;

  & span {

    font-weight: bold;
    padding-left: 1rem;
  }
  & i {

    font-size: 0.8rem;
    padding-right: 1rem;
    border-right: 1px solid #fff;
  }

  & a {

    color: #fff;
  }
}

.dropdown-pane {

  width: 23.1rem;
  background-color: #8a8a8a;
  color: #272727;
  border: none;
}

// fancy checkbox;
.fancy-checkbox i {
  font-size: 1.5rem;
  color: #8a8a8a;
}

.fancy-checkbox input[type="checkbox"],
.fancy-checkbox .checked {
  display: none;
}

.fancy-checkbox input[type="checkbox"]:checked ~ .checked {
  display: inline-block;
}

.fancy-checkbox input[type="checkbox"]:checked ~ .unchecked {
  display: none;
}

.artDesc {
  text-align: left;
  .title {
    font-size: 1.2rem;
    font-weight: 800;
  }
}

.artDesc, .artPrice {
  font-size: 1em;
  padding-top: 0.125rem;
}

.accordion-title {

  font-size: 1.125em;
  border: none;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  padding: 1.25rem 2.5rem 1.25rem .5rem;
  color: #000;
  font-weight: bold;

}

.accordion-content {

  padding: 0 1rem;
  border: none;
}

.config_accordion {

  padding: 1.25rem 0 1.25rem 0;
  border-bottom: 1px solid #eff0f1;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: #fff;
}

.artInfo, .artCheck {
  margin-top: 0.2rem;
}

.artCheck input[type=text] {
  border: 2px solid #8a8a8a;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  font-size: 0.7rem;
  padding: 0.2rem;
  text-align: center;
}

.artInfo {
  margin-left: 0rem !important;

  :focus {
    outline: none;
  }
  & i {

  }
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  font-family: "FontAwesome";
  content: '\f078';
  color: #8a8a8a;

}

.is-active > .accordion-title::before {
  font-family: "FontAwesome";
  content: '\f077';
}

.price_wrapper {

  list-style: none;
  text-align: right;

  & .priceTotal {
    font-weight: bold;
  }
  & .priceTotal, .priceEquipment, .priceMwSt, .priceBasic {
    font-size: 0.875rem;
  }
}

.smry_button {

  text-align: center;

  & span {

    font-weight: bold;
    padding-left: 1rem;
  }
  & i {

    font-size: 0.8rem;
    padding-left: 1rem;

  }

}

.text-center {
  text-align: center;
}

.summary {

  & h2 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1em;
  }

  .tablehead {
    background: #f3f3f3;
    border-bottom: 1px solid #ccc;
    padding: 0.625rem 0;

  }
  .categoryhead {
    font-weight: bold;
    border-top: 1px solid #ccc;
    font-size: 1.125em;
    border: none;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    padding: 1.25rem 0 0.625rem 0;
    color: #000;
    font-weight: bold;

  }
  .cell {
    padding: 0 0.625rem;

  }

  .item {
    padding: 0.625rem 0;
    border-bottom: 1px solid #eff0f1;

  }

  .val-top {
    text-align: right;
    padding-top: 2rem;
  }

}

td, tbody {
  border: none !important;
}

.actbtn {

  text-align: right;
  color: #757575;

  & i {
    margin: 0 0.5rem;

  }
}

td .totalRow {
  text-align: right;
  font-weight: bold;
}

.reveal {

  padding: 0;
  border: none;

  .close-button {
    right: 1.2rem;
    top: 1.9rem;
    color: #fff;
  }
}

.revealHeadline {
  padding: 2rem;
  background-color: darkgray;
  & span {
    font-size: 1.3rem;
    color: white;
    font-weight: bold;
  }
}

.revealSubline {
  padding: 2rem;
  background-color: darkgray;
  & .button {
    margin: 0;
  }
  & i {
    margin-right: 0.5rem;
  }
  & .compPrice {
    text-align: right;
    font-weight: bold;
    font-size: 1.2em;
    padding-bottom: 0.5rem;
  }
}

.revealContent {

  & .orbit-container {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  & .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: darkgray !important;
  }
  padding: 1rem 3rem 3rem 3rem;
  & thead {
    background: none;
    border: none;
    border-bottom: 1px solid black;
  }
  & table, thead, th, td {
    padding: 0.5rem 0;
  }
  & .orbit-previous, .orbit-next {
    padding: 1rem 0.5rem;
    background-color: darkgray;
    color: white;
  }
  & .orbit-next {
    right: -1.7rem;
  }
  & .orbit-previous {
    left: -1.7rem;
  }
  & p {
    margin-top: 2rem;
  }
}

.printContent {
  padding: 0 3rem 5rem 3rem;
}

.prinDesc span {
  font-weight: bold;
  margin-left: 0.2rem;
}

h3.code {
  font-weight: bold;
  margin: 1rem 0;
}

.specimg {

  img {
    margin: 1rem 0;
    max-width: 500px;
  }

}

.callout {
  border: none;
  & .accordion-title {
    border-bottom: none !important;

  }
  & .accordion-content {

    & .grid-x {
      padding: 1rem 0;
    }
    & .slider {
      text-align: left;
    }
    & .label {
      margin-top: 2rem;
    }
  }

}

.amortisation {
  text-align: center;
  & .armHeadline, & .images {
    font-size: 1.8rem;
    color: #272727;
    font-weight: bold;
    letter-spacing: 0.016em;
  }

}

.inquiry {
  margin-top: 2rem;

  & h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.pdfHeadline {

  & h1 {
    font-size: 1.5rem;
    color: darkgray;
  }

  & h2 {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 2px solid black;
    margin-bottom: 1rem;
  }
}

.pdftable, .pdftablesmry {

  td {
    vertical-align: text-top;
  }

  & .grey {
    font-size: 1.2rem;
  }
  padding: 1rem;
  & .tablehead {
    background: none;
    border: none;
  }
  & table, .tablehead, th, td {
    padding: 0.5rem 0;
  }

  & .orbit-next {
    right: -1.7rem;
  }
  & .orbit-previous {
    left: -1.7rem;
  }
  p {
    margin-top: 2rem;
  }

}

.pdfTotal {
  font-weight: normal;
}

.date_section {
  text-align: right;
  padding: 1rem 1rem 1rem 0;
  & .date_label {
    margin-bottom: 0;
    font-size: 0.8rem;
  }
  & .date {
    margin-top: -0.4rem;
  }
}

.intro_section {
  padding: 1rem 1rem 1rem 0;
}

header {

  height: 150px;

  .adress {
    position: absolute;
    top: 60px;
  }

  .logo {
    text-align: right;
    margin: 0;
    position: top;
    top: 0mm;
    right: 0;
  }

  .grey {
    color: #808080
  }

}

footer {

  .grey {
    color: #808080
  }

  .adress, .topline, .clientadress, .pdfHeadline, .pdfSubheadline, .intro_section, .code_section, .legal-details, .zsmHeadline, .pdftable, .pdftablesmry, .minidsclaimer, .order, .ordertable {

    text-align: left;
    margin: 0;
    position: relative;
    left: 0;
    width: 250mm;
  }

  .code_section {
    margin-top: 40mm;
    margin-top: 20px;
  }
  .legal-details {
    color: darkgray;
    font-size: 0.9rem;
  }

  ul {
    list-style: none;
    margin-left: -10px;
    margin-bottom: 0;
  }

  table.unstriped {
    margin-bottom: 0;
    border-collapse: separate
  }

  td {
    padding: 0.5rem 0.625rem 0;
  }

}

.PDF_Page {

  font-size: 12.5pt;

  .tablehead {
    border-bottom: 1px solid black !important;
  }

  .grey {
    color: #808080
  }

  .date_section {
    text-align: right;
    margin: 0;
    position: relative;
    top: 0;
    right: 0;
  }

  .adress, .topline, .clientadress, .pdfHeadline, .pdfSubheadline, .intro_section, .code_section, .legal-details, .zsmHeadline, .pdftable, .pdftablesmry, .minidsclaimer, .order, .ordertable {

    text-align: left;
    margin: 0;
    position: relative;
    left: 0;
    width: 250mm;

  }

  .adress {
    margin-top: 17mm;
  }
  .topline {
    font-size: 0.7em;
  }
  .clientadress, .zsmHeadline {
    margin-top: 5px;
  }
  .pdfHeadline {
    margin-top: 10px;
    h1 {
      font-size: 1.25rem;
      color: darkgray;
    }
  }
  .pdfSubheadline {
    margin-top: -10px;
    h1 {
      font-size: 1.25rem;
      color: darkgray;
    }
  }
  .intro_section {
    margin-top: 20px;
  }

  .zsmHeadline h1 {
    font-size: 1.2rem;
  }
  .pdftable {
    font-size: 0.9rem;
  }
  .pdftablesmry {
    font-size: 0.9rem;
  }
  .minidsclaimer {
    font-size: 0.9rem;
    width: 70%;
    margin-top: 80px;
  }
  .order {
    margin-top: 20px;
    margin-bottom: 80px;
    font-size: 0.8rem;
  }
  .ordertable {
    font-size: 0.9rem;
    & tr {
      background-color: #DBDBDB;
    }
    & td {
      text-align: center;
      border-right: 1px solid black !important;
    }
    & td:last-child {
      border-right: none !important;
    }

  }
  .bignumber {
    font-size: 1.5rem;
  }
  .amortheadline {
    padding: 100px 0 100px 0;
  }
  .amorttableh {
    padding-bottom: 20px;
  }
  h5.armHeadline, p.images {
    font-weight: bold;
    font-size: 2rem;
  }

  .subtotal {
    background-color: #DBDBDB;
    & td {
      margin-top: 30px;
    }
  }
  .indented {
    page-break-inside: avoid;
    & td:first-child {
      padding: 0 20px;
    }
  }
  .categoryhead {
    font-weight: bold;
    & td {
      padding-top: 30px;
    }
  }
  & table {
    margin-bottom: 0;
    margin-left: -10px;
  }
  & table.unstriped tbody tr {
    border-bottom: none;
  }

  ul {
    list-style: none;
    margin-left: -10px

  }

  .specimg {

    & img {
      margin: 1rem 5rem;
      max-width: 400px;
    }
  }

}

.pagebreak {
  page-break-before: always;
}

.pdfheader .adress, .pdfheader .contact, .pdfheader .clientadress {
  margin-left: 0 !important;
}

.headnav, .breadcrumb-counter-nav, .breadcrumb-counter-nav .breadcrumb-counter-nav-item.current::before {
  background: #757575;
}

.breadcrumb-counter-nav-item.current::before {
  background: transparent !important;
}

.breadcrumb-counter-nav li {
  color: #fff;
}

.lnk-btn {
  padding: 0.4rem 2rem;
  text-align: right;
  vertical-align: middle;
}

.orbit-image {
  width: auto;
  max-height: 500px;
}

.lnk-btn .fa {
  color: #fff;
}

.fixed {
  position: fixed;
  width: 100%;
}

.orbit-previous, .orbit-next {

  padding: 1rem 0.5rem;
  background-color: transparent;
  color: darkgray;
}

.orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
  background-color: transparent;
}

.orbit-next {
  right: -0.6rem;
}

.orbit-previous {
  left: -0.6rem;
}

.orbit-wrapper:focus, .orbit-container:focus {
  outline: none;
}

.confirmation {

  & h2 {
    font-size: 2rem;
    color: #272727;
    font-weight: 300;
    letter-spacing: 0.016em;
    margin: 3.5rem 0 2rem 0;
  }
}

.dvtconfig-header {
  width: 100%;
  background-color: $white;

  .logo {
    height: 50px;
    width: 160px;
  }

  ul {
    background-color: $white;
  }

  li {
    display: inline-block;
  }

  .nav-links {
    margin-left: 15px;
  }

  a {
    color: $dark-gray;
    transition: color 0.5s ease;
    display: block;
  }

  a:hover {
    color: darken($dark-gray, 50%);
    transition: color 0.5s ease;
  }

  input {
    margin-right: 0px;

    @include breakpoint(large down) {
      width: 150px;
    }
  }

  &.top-bar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .menu > .main-nav-link > a {
    padding: 1.5em 0.85em 1.9em 0.85em;
  }
}

.dvtconfig-header-top {
  width: 100%;
  background-color: darken($dark-gray, 20%);
  height: 40px;
  padding: 8px 20px 10px 20px;

  .fa {
    color: $white;
    font-size: 1.5rem;
  }
}

.dvtconfig-header-top-message {
  color: lighten($dark-gray, 100%);
  float: left;
}

.dvtconfig-header-top-links {
  color: lighten($dark-gray, 100%);

  ul li {
    display: inline;
    padding-left: 20px;
  }

  a {
    color: lighten($dark-gray, 50%);
    transition: color 0.5s ease;
  }

  a:hover {
    color: lighten($dark-gray, 100%);
    transition: color 0.5s ease;
  }
}

.dvtconfig-header-off-canvas {
  background-color: darken($dark-gray, 20%);

  .menu {
    margin: 2rem 0;
  }

  .menu a {
    color: white;
    padding: 1.5rem 1rem;
    border-bottom: 1px white solid
  }
}

.dvtconfig-header-mobile {
  background-color: #757575;
  display: flex;
  padding: rem-calc(10) rem-calc(20);
  align-items: center;
  justify-content: space-between;
}

.dvtconfig-header-mobile-left,
.dvtconfig-header-mobile-right,
.dvtconfig-header-mobile-center {
  flex: 1 0 0;
}

.dvtconfig-header-mobile-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dvtconfig-header-mobile-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dvtconfig-header-mobile-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

.dvtconfig-header-search-exandable input[type=search] {
  margin: 0 1rem;
  background: $light-gray url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px $medium-gray;
  padding: 9px 10px 9px 26px;
  width: 35px;
  border-radius: 5000px;
  transition: all 0.5s;
}

.dvtconfig-header-search-exandable input[type=search]:focus {
  width: 120px;
  background-color: $white;
  border-color: $primary-color;
  box-shadow: 0 0 5px rgba($primary-color, 0.5);
}

.dvtconfig-header-search-exandable input:-moz-placeholder {
  color: $dark-gray;
}

.dvtconfig-header-search-exandable input::-webkit-input-placeholder {
  color: $dark-gray;
}

.dvtconfig-header-search-exandable input[type=search]:hover {
  background-color: $white;
}

.close-button {
  color: white !important;
}

.referencelist {

  .categoryhead {
    font-weight: 300;
    font-size: 2rem;
  }

  li.title {
    font-size: 1.1rem;

    padding-bottom: 5px;
    color: #808080
  }
  span.distance {

    font-weight: 300;
    color: black;
    font-size: 0.7rem;
  }

}

.indication {
  padding-top: 150px;
}

.revealButtons {

  padding: 1rem 3rem;

}

.loginpage {
  height: 100vh;
  background: url("../img/background.jpg") no-repeat fixed center;

  & .log-in-form {
    padding: 1rem;
    background-color: white;
    margin-top: 10rem;
  }
}

.inquiry .large-4 .text-center img {
  max-height: 350px;
}

.revealFieldset {
  padding: 1rem 3rem 3rem 3rem;
  margin-top: -2rem;
}

.sub .accordion-title {
  font-size: 0.9em;
  border: none;
  border-bottom: none !important;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  padding: 0.5rem 0;
  color: #000;
  font-weight: normal;

}

.sub .accordion-content {

  padding: 0;
  border-bottom: none !important;
}

.title {
  font-size: 1.2rem;
  font-weight: 800;
}

.medium-4 .tabs-content {
  border: none;
}

.medium-4 .tabs-title {
  width: 100%;
  text-align: center;
  outline: none !important;
  border-bottom: 1px solid #ccc;

  a {
    font-size: 1.5rem;
    font-weight: 600;

  }
}

.medium-4 .tabs {
  border-bottom: none;
}

.medium-4 .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
  background: #eaeaea;
  color: #000;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom_frame {
  padding: 0 3rem;

  > .medium-8 {
    padding-left: 3rem;
  }
}

.included {
  font-style: italic;
}

.back_button {
  padding-top: 2.25em;
  padding-bottom: .625em;
  text-align: right;
}

.bold {
  font-weight: bold;
}

.loadconf {
  width: 50% !important;
}

.tbl-hdl {
  font-size: 1.125rem;
  font-weight: bold;
  background: #f3f3f3;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 0.625rem 0.625rem;
}

.grey {
  background-color: #f9f9f9 !important;
}

.discl {
  text-decoration: underline;
}

.right p {
  text-align: right;
}

.right {
  text-align: right;
}

// Print Styles

@page {
  margin-top: 4cm;
  margin-left: 2.3cm;
  margin-right: 1.5cm;
  margin-bottom: 0.5cm;
  counter-increment: page;
  counter-reset: page 1;

}

table.pdf-container {

  font-family: "myriad-pro", sans-serif;
  font-size: 10pt;

}

thead.pdf-header {
  display: table-header-group !important;
}

tfoot.pdf-footer {
  display: table-footer-group;

}

.pdf-footer-cell {
  height: 70px;
}

.pdf-hdl-small {
  font-size: 7pt;
  margin-top: 75px
}

.pdf-hdl-sl {
  font-size: 7pt;
}

.pdf-adr {
  margin-top: -25px;
  font-size: 10pt;
  line-height: 1.2;
  ul {
    list-style: none;
    margin-left: 0;
  }
}

.pdf-sbhdl {
  margin-top: 25px;
  margin-bottom: 50px;
  .hdl {
    font-size: 11pt;
    font-weight: bold;
  }
  .plcdte {
    font-size: 10pt;
    text-align: right;
  }
}

.pdf-hdl {

  margin-top: 10mm;
  margin-bottom: 10mm;
  ul {
    list-style: none;
    margin-left: 0;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
    li {
      font-size: 12
    }
    li:first-child {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #e3e3e3;
      line-height: 0.1em;
      margin: 10px 0 15px;
      font-size: 17pt;
      span {
        background: #fff !important;
        -webkit-print-color-adjust: exact;
        padding: 0 10px;
      }
    }
  }
}

.pdf-txt {
  font-size: 11pt;
}

.pdf-footer {

  ul {
    list-style: none;
    margin-left: 0;
    font-size: 6.25pt;
  }
}

.pdf-container {

  thead {
    background: none;
    border: none;
    font-size: 6pt;
    font-weight: normal;
    color: #575756 !important;
    -webkit-print-color-adjust: exact;
  }

  tfoot {
    background: none;
    border: none;
    font-size: 6pt;
    font-weight: normal;
  }
}

.footer-info {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.text-right {
  text-align: right;
}

.pdf-pagination {
  font-size: 10pt;
  margin-top: 15px;
  margin-bottom: 15px;
}

.pdf-thmb {
  margin-bottom: 25px;

  img {
    width: 50mm;
  }

}

.pdf-tbl-head td {
  font-size: 10pt;
  font-weight: normal;
  background-color: rgba(87, 87, 87, 0.1) !important;
  -webkit-print-color-adjust: exact;
}

.pdf-tbl-headline {
  font-size: 11pt;
  font-weight: bold;
  color: #575756 !important;
  -webkit-print-color-adjust: exact;

  td {
    padding-top: 1.5rem;
  }
}

.pdf-tbl-sbhdl {
  font-size: 12pt;
  font-weight: bold;
  color: #878786 !important;
  -webkit-print-color-adjust: exact;

}

.pdf-artnr {
  font-size: 8pt;
  color: #808285 !important;
  -webkit-print-color-adjust: exact;
  vertical-align: top;
}

.pdf-dscr {
  color: #58595B;

  ul {
    list-style: none;
    margin-left: 0;
  }

  .dscr-title {
    font-size: 10pt;
  }
  .dscr-kurztext {
    font-size: 8pt;
  }
  .dscr-langtext {
    font-size: 8pt;
    color: #808285;
    margin-top: 25px;
  }
}

.pdf-count, .pdf-price, .pdf-sum {
  font-size: 10pt;
  color: #58595B;
  text-align: right;

}

.pdf-included {
  font-style: italic;
}

.pdf-tbl-summary {

  font-weight: bold;
  font-size: 11pt;
  color: #58595B !important;
  -webkit-print-color-adjust: exact;

}

.border-top {
  border-top: 1px solid rgba(87, 87, 87, 0.1) !important;
}

.pdf-pricesmry {
  font-size: 11pt;
  table tbody td {
    padding: 0.25rem 0.625rem 0.25rem;
  }
  table tbody tr {
    border-bottom: none;
  }

  table tbody .border-bottom {
    border-bottom: 1px solid rgba(87, 87, 87, 0.1) !important;
  }

  table tbody tr:nth-child(3), table tbody tr:last-child {
    border-bottom: 1px solid rgba(87, 87, 87, 0.1);
  }

}

.pdf-amo {
  font-size: 9pt;
  table tbody td {
    padding: 0.25rem 0.625rem 0.25rem;
  }
  table tbody tr {
    border-bottom: none;
  }

  table tbody .border-bottom {
    border-bottom: 1px solid rgba(87, 87, 87, 0.1) !important;
  }

  table tbody tr:nth-child(3), table tbody tr:last-child {
    border-bottom: 1px solid rgba(87, 87, 87, 0.1);
  }

}

.pdf-leasesmry {

  table tbody {

    td {
      padding: 0.8rem 0.625rem 0.7rem;
    }
    tr {
      border-bottom: none;
      background-color: rgba(87, 87, 87, 0.1) !important;
      -webkit-print-color-adjust: exact;
      &:nth-child(3), &:first-child {
        border-bottom: 1px solid rgba(87, 87, 87, 0.1);
      }

    }
  }
  font-size: 11pt;

}

.pdf-tbl {
  font-weight: 400;
}

.pdf-text-lease p {
  font-size: 8pt;
  line-height: 1.2;
  margin-top: 25px;
}

.pdf-txt-sign {
  font-size: 10pt;
  margin-top: 25px;

}

.pdf-text-lease {

}

.pdf-generalinfo p {
  font-size: 11pt;
  font-weight: bold;
  border-bottom: 1px solid grey;
  padding-bottom: 10px;

}

.pdf-config {
  font-size: 11pt;
  padding-top: 44mm;
}

.pdf-ref-tbl {

  .pdf-tbl-head {
    td {
      font-size: 11pt;
      font-weight: bold;
    }
  }

  tbody {
    vertical-align: top;
  }

  tbody tr:last-child {
    border-bottom: none;
  }

  .ref-title {
    font-size: 11pt;
    font-weight: bold;
    padding-top: 25px;
    border-bottom: 1px solid rgba(87, 87, 87, 0.1) !important;
  }

  ul {
    list-style: none;
    margin-left: 0;
    font-size: 10pt;
  }

}

.nobreak {
  display: block;

  page-break-inside: avoid;

}

.pdf-tbl-amort {
  font-size: 9pt;
  line-height: 1.2;
}

.pdf-amortsmry {
  background-color: rgba(87, 87, 87, 0.1) !important;
  -webkit-print-color-adjust: exact;
  font-size: 10pt;
  color: #575756 !important;
  -webkit-print-color-adjust: exact;
  margin: 15px 0 40px 0;
  ul {
    list-style: none;
    margin-left: 0;
    padding-top: 10px;
  }
  li:first-child, li:nth-child(3) {
    font-weight: bold;
    font-size: 16pt;
  }
}

.header-info, .pdf-footer {
  font-weight: 400;
}

#printme {
  font-size: 10pt;
}

#printme::after {
  counter-increment: page;
  content: counter(page) ' von ' counter(pages);
}

.pdf-logo img {
  width: 16mm;
}

.ref-pdf tr {
  border-bottom: none !important;
}

.ref-nobreak {
  page-break-inside: avoid;
  ul {
    color: #878786 !important;
    -webkit-print-color-adjust: exact;
  }
}

.artPrice {
  text-align: right;

}

.annotation {

  border-bottom: none !important;
  margin-top: 1rem;
  ul {
    list-style: none;
    margin-left: 0;
    font-size: 0.7rem;
  }
}

.div-border {
  border-top: 2px solid rgba(10, 10, 10, 0.8);
  border-bottom: none !important;
}

.mdprice {
  text-align: right;
}

.val-top {
  vertical-align: top;
  padding-top: 2rem;
}

.amort-tbl thead {
  background: none;
  border: none !important;

  tr {
    border-bottom: 1.5px solid rgba(10, 10, 10, 0.8);
  }

}

.artInfo {
  text-align: right;
}

#dvt_info li {

  font-size: 0.8rem;
}

/* Small only */
@media screen and (max-width: 39.9375em) {

  .title_wrapper h1 {
    font-size: 1.4rem;
    text-align: center;
  }
  .custom_frame {
    padding: 0 1rem;

    .medium-8 {
      padding-left: 1rem;
    }
  }
  .lnk-btn {
    padding: 0.4rem 0;
  }
  .back_button {
    padding-top: 0;
    text-align: left;
  }

  .artInfo, .artCheck {
    margin-top: 0.4rem;
    padding-right: 0.6rem;
  }

  .artDesc .title {
    font-size: 1rem;

  }
  .summary {
    .val-top {
      padding-top: 0;
      text-align: right;

    }

    .price {

    }

    .title {
      font-size: 1rem;
    }
  }

  .sub .accordion-title {
    padding-right: 3rem;
    line-height: 1.2;
  }

  .loadconf {
    width: 100% !important;
  }

}