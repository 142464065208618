$font-color: #fff;
$nav-background: #2C3E50;


body {
  counter-reset: section;
}

.breadcrumb-counter-nav {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  list-style: none;
  background: $nav-background;

  .breadcrumb-counter-nav-item {
    position: relative;
    flex: 1 0 auto;
    padding: 1rem 1rem;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      background: darken($nav-background, 10%);
    }

    &::before {
      counter-increment: section;
      content: counter(section)".";
      position: relative;
      margin-left: 0.5rem;
      color: $white;
      right: 0.2rem;

    }

    &.current {
      background: darken($nav-background, 10%);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1rem;
        width: 0;
        height: 0;
        border-top: 1.75rem solid transparent;
        border-bottom: 1.75rem solid transparent;
        border-left: 1rem solid darken($nav-background, 10%);
        z-index: 300;
      }

      &::before {
        background-color: lighten($nav-background, 45%);
      }
    }

    a {
      color: $font-color;
      font-size: 0.875rem;
    }
  }

  @media only screen and (max-width: 52em) {
    flex-direction: column;
  }
}
